<template>
  <el-dialog
    append-to-body
    :visible.sync="dialog"
    :title="title"
    width="500px"
    class="edittag-dialog"
  >
    <div class="content">
      <h3>系统将进行以下操作</h3>
      <p
        v-for="(item,index) in pList"
        :key="index"
      >{{item}}</p>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { toggleActiveStatus } from "@/api/ib/tCustMtaccount";
  import { twoDecimal } from '@/utils/validate'
export default {
  props: {
    rowData: {
      type: Object,
      default(){
        return {
          id: '',
        }
      }
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      dieTips: ['1.未平仓订单强制平仓','2.个人中心隐藏账户,不计入账户名额','3.交易和登录状态改为禁用'],
      liveTips: ['1.个人中心显示账户,计入账户名额','2.交易和登录状态改为启用'],
    }
  },
  computed: {
    title() {
      return this.rowData.isActive ? '确认注销': '确认激活';
    },
    pList(){
      if(this.rowData.isActive){
        return this.dieTips;
      }else {
        return this.liveTips;
      }
    }
  },
  methods: {
    getTagInfo(){
      getAccountTagList().then((res) => {
        console.log(res)
        this.tagOption = res;
      })
    },
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      toggleActiveStatus({
        id: this.rowData.mtId,
        isActive: this.rowData.isActive ? 0: 1
      }).then((res) => {
        this.dialog = false;
        this.$notify({
          title: '操作成功',
          type: 'success',
          duration: 2500
        });
        this.$parent.init();
      })
    },
  }
}
</script>

<style lang="less">
.edittag-dialog {
  p {
    margin: 0;
    padding: 0;
  }
  .count-box {
    .sufxx {
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      z-index: 99;
    }
    .el-input__inner {
      padding-left: 30px;
    }
  }
}
</style>
