<template>
  <div class="app-container summary-page">
    <cent-tab
        ref="centtab"
        @send-type="sendType"
    ></cent-tab>
    <eHeader
        :query="query"
        @toQuery="toQuery"
        @clear="clear"
        @openMT4="openMT4Hanlde"
    />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      size="small"
      style="width: 100%;"
      show-summary
      :summary-method="getSummaries"
    >
       <el-table-column label="账户类型" width="120">
        <template slot-scope="scope">
          <span>{{ getAccountType(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          v-if="checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_TRADE_INFO'])"
          label="账户标签"
          width="120"
      >
        <template slot-scope="scope">
          <div
              @click="editTag(scope.row)"
              class="tag-outer">
            <span>{{ scope.row.tagName }}</span>
            <div>{{ scope.row.remark || '-' }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="MT4账户信息" width="150">
        <template slot-scope="scope">
          <span>
            {{scope.row.accType}}
            <br />
          </span>
          <span>
            {{scope.row.login}}
            <br />
          </span>
          <span>1:{{scope.row.leverage}}</span>
        </template>
      </el-table-column>

      <el-table-column label="客户信息" width="180">
        <template slot-scope="scope">
          <span>
            {{scope.row.userName}}
            <br />
          </span>
          <span>
            {{scope.row.id}}
            <br />
          </span>
          <span>{{scope.row.email}}</span>
        </template>
      </el-table-column>

      <el-table-column label="客户经理" width="130">
        <template slot-scope="scope">
          <span>
            {{scope.row.managerName}}
            <br />
          </span>
          <span>
            {{scope.row.managerId}}
            <br />
          </span>
        </template>
      </el-table-column>

      <el-table-column label="归属代理" width="130">
        <template slot-scope="scope">
          <span>
            {{scope.row.agentName}}
            <br />
          </span>
          <span>
            {{scope.row.belongId}}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="资金信息" width="180">
        <template slot-scope="scope">
          <span>
            账户余额:{{scope.row.balance||0}}
          </span>
          <br/>
          <span>账户净值:{{scope.row.equity||0}}</span>
        </template>
      </el-table-column>
      <el-table-column
          v-if="getColFlag()"
          label="交易信息"
          width="180"
      >
        <template slot-scope="scope">
          <span>
            平仓手数:{{scope.row.volume || 0}}
            <br/>
          </span>
          <span>
            返佣总额:{{scope.row.producedCommission || 0}}
          </span>
          <br/>
          <span>
            总盈亏:{{scope.row.profit||0}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="出入金信息" width="180">
        <template slot-scope="scope">
          <span>
            总入金:{{scope.row.deposit||0}}
            <br />
          </span>
          <span>
            总出金:{{scope.row.withdraw||0}}
            <br />
          </span>
          <span>净入金:{{(scope.row.deposit - scope.row.withdraw).toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="enableReadOnly"
          label="状态"
          width="100px"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.isActive == 1 ? '激活' : '注销' }}</div>
          <div>{{ scope.row.enable=='0' ? '登录禁用' : '登录启用' }}</div>
          <div>{{ scope.row.enableReadOnly == '0' ? '交易启用' : '交易禁用' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="开户时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_SELECT'])"
            type="success"
            size="mini"
            class="margin-right5"
            @click="to(1,scope.row.login,scope.row.serverId,false)"
          >查看</el-button>
          <el-button
                  v-if="checkPermission(['ADMIN','CUST_SERVER_ALL','CUST_SERVER_SELECT'])"
                  @click="openView(scope.row)"
                  size="mini"
                  type="success"
                  class="margin-right5"
          >客服查看</el-button>
          <el-dropdown
            size="mini"
            v-if="getMoreBtnShow(scope.row)"
          >
            <el-button size="mini" type="success">
              更多操作
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                      class="dropdown-item"
                      v-if="checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_EDIT'])"
              >
                <div
                        @click="editHandle(scope.row)"
                >账户编辑</div>
              </el-dropdown-item>
              <el-dropdown-item
                class="dropdown-item"
                v-if="checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_EDIT'])"
              >
                <div
                  @click="openCus(scope.row)"
                >客户信息</div>
              </el-dropdown-item>
              <!--模拟和体验账户不需要入金-->
              <el-dropdown-item
                class="dropdown-item"
                v-if="getUpAccountBtn(scope.row)"
                @click.native="upAccountHandle(scope.row)"
              >
                <div
                >上账</div>
              </el-dropdown-item>
              <el-dropdown-item
                  class="dropdown-item"
                  v-if="getUpAccountBtn(scope.row)"
                  @click.native="depositHandle(scope.row)"
              >
                <div
                >入金</div>
              </el-dropdown-item>
              <el-dropdown-item
                      class="dropdown-item"
                      v-if="checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_RESET_PASSWORD'])"
                      @click.native="resetPwd(scope.row)"
              >
                <div
                >重置密码</div>
              </el-dropdown-item>
              <el-dropdown-item
                  class="dropdown-item"
                  v-if="checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_EDIT'
])"
                  @click.native="toggleStatus(scope.row)"
              >
                <div
                >{{getBtnText(scope.row)}}</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <el-dialog
        title="权限验证"
        :visible.sync="dialogFormVisible"
        append-to-body
        width="30%"
    >
      <div class="send-li">
        <li>
          <span class="code-font">手机验证码</span>
        </li>
        <li>
          <el-input placeholder="请输入手机验证码" v-model="smsCode"></el-input>
        </li>
        <li>
          <span v-show="show" @click="getCode" class="count">获取验证码</span>
          <span v-show="!show" class="count">{{count}} s</span>
        </li>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCode()">确 定</el-button>
      </div>
    </el-dialog>
    <detail
      ref="detail"
      :sup_this="sup_this"
      :isCheck="false"
      :data="viewData"
    />
    <golden-dialog
      :row-data="currentRow"
      ref="goldendialog"
    ></golden-dialog>
    <mt4-edit-dialog
      ref="mt4edit"
      :dialog-show="mt4EditDialogShow"
      @close-dialog="closeDialogHandle"
      :user-info="userInfo"
      :group-self="smallMtGroups"
      @refresh="refresh"
    ></mt4-edit-dialog>
    <edit-tag-dialog
      ref="tagdilog"
      :row-data="userInfo"
    ></edit-tag-dialog>
    <active-or-cancel
        ref="activeorcancel"
        :row-data="userInfo"
    ></active-or-cancel>
    <open-mt4account-dialog
        ref="openmt4"
        :currency="currency"
        :dialog-show="openMt4accountDialogShow"
        @close-dialog="closeOpenMt4DialogHandle"
        :group-self="smallMtGroups"
        @refresh="refresh"
    ></open-mt4account-dialog>
  </div>
</template>

<script>
  import openMt4accountDialog from '@/components/customer/accountmgr/openMt4accountDialog'
  import activeOrCancel from '@/components/customer/accountmgr/activeOrCancel'
  import editTagDialog from '@/components/customer/accountmgr/editTag'
  import { mapGetters } from 'vuex'
  import { resetCustPassword,toggleActiveStatus } from "@/api/ib/tCustMtaccount";
  import mt4EditDialog from '@/components/public/mt4EditDialog'
  import goldenDialog from '@/components/customer/accountmgr/goldenDialog'
import checkPermission from "@/utils/permission";
import initDataMix from "@/mixins/initData";
import { parseTime } from "@/utils/index";
import { sendPhoneVerCode, authPhoneVerCode } from "@/api/system/user";
import eHeader from "@/components/customer/accountmgr/header";
import detail from "@/components/ib/merchantinfo/detail";
import { initData } from '@/api/data';
export default {
  name:'aaccountmgr',
  components: {
    eHeader,
    detail,
    goldenDialog,
    mt4EditDialog,
    editTagDialog,
    activeOrCancel,
    openMt4accountDialog,
  },
  mixins: [initDataMix],
  data() {
    return {
      viewData:{},
      delLoading: false,
      sup_this: this,
      selectedRow: null,
      dialogFormVisible: false,
      smsCode: "",
      show: true,
      count: "",
      timer: null,
      id: "",
      login: "",
      statistics:{
        balanceSum: '',
        equitySum: '',
      },
      currentRow:{
        userName: '',
        login: ''
      },
      mt4EditDialogShow: false,
      userInfo:{},
      smallMtGroups: {},
      leverageEcn: [],
      leverageStd: [],
      leveragePro: [],

      openMt4accountDialogShow: false,
      currency: 'USD',
    };
  },
  created() {
    this.$nextTick(() => {
      this.init();
    });
  },
  updated(){
    this.$nextTick(() => {
      this.setSumProfitColor();
    })
  },
  computed: {
    ...mapGetters(['mtGroups']),
  },
  methods: {
    parseTime,
    checkPermission,
    closeOpenMt4DialogHandle(){
      this.openMt4accountDialogShow = false;
    },
    sendType(type){
      this.currency = type;
      this.page = 0;
      this.init();
    },
    depositHandle(item){
      this.$store.commit('setDepositAccount',item.login);
      this.$router.push({name: 'depositAction'});
    },
    toggleStatus(item){
      this.userInfo = item;
      this.$refs.activeorcancel.dialog = true;
    },
    getBtnText(item){
      return item.isActive ? '注销': '激活'
    },
    closeDialogHandle(){
      this.mt4EditDialogShow = false;
    },
    getColFlag(){
      return checkPermission(['ADMIN','CUSTMTACCOUNT_ALL', 'CUSTMTACCOUNT_TRADE_INFO'])
    },
    editTag(row){
      this.userInfo = row;
      if(checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_TRADE_INFO'])){
        if(!this.$refs.tagdilog.tagOption.length){
          this.$refs.tagdilog.getTagInfo();
        }
        this.$refs.tagdilog.dialogShow = true;
      }
    },
    editHandle(row){
      // todo
      this.userInfo = row;
      this.handleMt4GroupsCommon('mt4edit');
      this.mt4EditDialogShow = true;
    },
    handleMt4GroupsCommon(refdom){
      this.$store.dispatch("GetMtGroups").then((res) => {
        this.smallMtGroups = res.statistics;
        for (let key in this.smallMtGroups) {
          for (let i = 0; i < this.smallMtGroups[key].length; i++) {
            if(this.smallMtGroups[key][i].id == row.groupDetailId){
              for (let j = 0; j <this.mtGroups.length ; j++) {
                if(this.mtGroups[j].groupName == this.smallMtGroups[key][i].groupType){
                  this.$refs[refdom].setLeverage(this.mtGroups[j].leverageList);
                  break;
                }
              }
            }
          }
        }
      });
    },
    refresh(){
      this.init();
      this.mt4EditDialogShow = false;
    },
    queryAndSetlevel(leverageString){
      let levList = leverageString.split(",");
      let tempList = [];
      for (let i = 0; i < levList.length; i++) {
        let lev = parseInt(levList[i]);
        let levName = "1:" + levList[i];
        let params = {
          levName: levName,
          lev: lev
        };
        tempList[i] = params;
      }
      return tempList;
    },
    openCus(data) {
      const _this = this.$refs.detail;
      _this.title = "查看";
      const url = 'crm/tCustInfo'
      const sort = 'createTime,desc'
      const params = { basicInfo:data.id,page: 0, size: 10, sort: sort ,flag:'false'}
      initData(url, params).then(res => {
          this.viewData = res.content[0];
        _this.dialog = true;

        }).catch(err => {
          this.loading = false
        })

    },
    openView(data) {
      this.dialogFormVisible = true;
      this.login = data.login;
      this.id = data.id;
    },
    resetPwd(row){
      const params = {
        custId: row.id,
        login: row.login
      };
      this.$confirm("请确认是否重置客户MT4账户密码?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
              .then(() => {
                //接口调用
                resetCustPassword(params)
                        .then(res => {
                          this.$notify({
                            title: res.message,
                            type: "success",
                            duration: 2500
                          });
                          this.cancel();
                        })
                        .catch(err => {});
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消"
                });
              });
    },
    beforeInit() {
      //加入了美分账户 todo
      this.url = "crm/getTCustMtaccountsAndCustInfos";
      const sort = "cm.id,desc";
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
        currency: this.$refs.centtab.type,
      };

      if(this.$route.query.agencyId){
        this.query.agentInfo = this.$route.query.agencyId;
        this.$route.query.agencyId = null;
      }
      const query = this.query;
      const mt4Info = query.mt4Info;
      const custInfo = query.custInfo;
      let manageInfo = query.manageInfo;
      const agentInfo = query.agentInfo;
      const businessType = query.businessType;
      const tier = query.tier;


      if (mt4Info != null && mt4Info != "") {
        this.params["mt4Info"] = mt4Info;
      }
      if (custInfo != null && custInfo != "") {
        this.params["custInfo"] = custInfo;
      }
      if (manageInfo != null && manageInfo != "") {
        this.params["manageInfo"] = manageInfo;
      }
      if (this.query.agentInfo != null && this.query.agentInfo != "") {
        this.params["agentInfo"] = this.query.agentInfo;
      }
      if (businessType !== null && businessType !== "") {
        this.params["businessType"] = businessType;
      }
      if (tier!== null && tier !== "") {
        this.params["tier"] = tier;
      }
      if(this.query.tag){
        this.params.tagId = this.query.tag;
      }
      if(this.query.status !== ''){
        this.params.isActive = this.query.status;
      }
      //脱敏标志
      this.params["flag"] = true;
      return true;
    },
    to(btnType, login,serverId,isReadOnly) {
      if (btnType == 1) {
        this.$router.push({
          name: "accdetail",
          query: {
            serverId:serverId+'',
            login: login+'',
            isReadOnly:isReadOnly
          },
        });
      }
    },
    getCode() {
      let params = { id: this.id };
      sendPhoneVerCode(params)
        .then(res => {
          this.$notify({
            title: res.message,
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {});
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    submitCode() {
      this.dialogFormVisible = false;
      let params = {
        id: this.id,
        verCode: this.smsCode
      };
      authPhoneVerCode(params)
        .then(res => {
          if (res.status == 10000) {
            this.to(1, this.login ,true);
          } else {
            this.$notify({
              title: res.message,
              type: "error",
              duration: 2500
            });
          }
        })
        .catch(err => {});
    },
    getAccountType(row){
      if(row.businessType == 6){
        return '体验账户'
      }else if(row.businessType == 5){
        return '模拟账户'
      }else if(row.businessType == 3){
        return '钱包账户'
      }else if(row.businessType == 1){
        if(row.tier==1){
          return 'Social(信号)'
        }else {
          return 'Social(投资)'
        }
      }else if(row.businessType == 2){
        if(row.tier==1){
          return 'MAM(信号)'
        }else {
          return 'MAM(投资)'
        }
      }else{
        return '普通账户'
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        switch (column.label) {
          case '账户类型':
            sums[index] = '合计';
            break;
            // white-space 换行
          case '资金信息':
            sums[index] = `账户余额: ${this.statistics.balanceSum}\n账户净值: ${this.statistics.equitySum}`;
            break;
          case '交易信息':
            sums[index] = `平仓手数: ${this.statistics.volumeSum}\n返佣总额: ${this.statistics.producedCommissionSum}\n总盈亏: ${this.statistics.profitSum}`;
            break;
          case '出入金信息':
            if(this.getColFlag()){
              sums[index] = `总入金: ${this.statistics.depositSum}\n总出金: ${this.statistics.withdrawSum}\n净入金: ${(this.statistics.depositSum - this.statistics.withdrawSum).toFixed(2)}`;
            }
            break;
        }
      });
      return sums;
    },
    setSumProfitColor(){
      const s_table = document.getElementsByClassName('el-table__footer-wrapper')[0];
      if(s_table && s_table.getElementsByTagName('tr')[0]){
        const child_tr_childivs = s_table.getElementsByTagName('tr')[0].childNodes;
        child_tr_childivs.forEach((item,index) => {
          if(index == 5 || index == 6 || index == 7 || index == 8){
            let divEle = item.getElementsByClassName('cell')[0];
            divEle.setAttribute('style', 'white-space: pre-wrap');
          }
        })
      }
    },
    upAccountHandle(row){
      this.currentRow = row;
      this.$refs.goldendialog.dialog = true;
    },
    getUpAccountBtn(row){
      return checkPermission(['ADMIN','DEPOSIT_ADMIN']) && (row.businessType !==6 && row.businessType !== 5);
    },
    clear(){
      this.query = {};
      this.toQuery();
    },
    getMoreBtnShow(row){
      return checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_EDIT']) || checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_EDIT']) || this.getUpAccountBtn(row) || checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_RESET_PASSWORD'])
    },
    openMT4Hanlde(){
      this.openMt4accountDialogShow = true;
    }
  }
};
</script>
<style lang="less" scoped>
  .my-button {
    margin-right: 5px;
  }
</style>
<style scoped>
.tag-outer {
  cursor: pointer;
  color: #409EFF;
}
.code-font {
  color: black;
  font-size: 16px;
  font-weight: 600;
}
.send-li li {
  display: inline-block;
  margin-right: 20px;
}
.count {
  padding: 10px 30px;
  background-color: rgb(30, 144, 255);
  color: aliceblue;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
</style>

