<template>
  <el-dialog
      title="MT4开户"
      :visible="dialogShow"
      append-to-body
      width="500px"
      :before-close="beforeHandle"
      class="openMt4account-dialog"
  >
    <div class="content">
      <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="90px"
          label-position="right"
      >
        <el-form-item
          label="客户/代理"
          prop="custId"
        >
          <el-select
              class="accessMoney"
              v-model="form.custId"
              placeholder="姓名/ID"
              remote
              filterable
              :remote-method="getAccountList"
              :loading="loading"
              style="width: 100%"
          >
            <el-option
                v-for="item in customerList"
                :key="item.value"
                :label="item.userName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="MT4分组"
            prop="mtGroup"
        >
          <el-select
              placeholder="请选择"
              v-model="form.mtGroup"
              style="width: 100%"
          >
            <el-option
                v-for="(group, index) in groupList"
                :key="index"
                :label="group.groupName"
                :value="group.groupName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="杠杆" prop="leverage">
          <el-select
              style="width: 100%"
              class="select"
              v-model="form.leverage"
              placeholder="请选择"
          >
            <el-option
                v-for="item in leverList"
                :key="item.lev"
                :label="item.levName"
                :value="item.lev"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input
              placeholder="请输入"
              v-model="form.nickname"
              maxlength="10"
              show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button
          type="text"
          @click="closeDialog"
      >取消</el-button>
      <el-button
          type="primary"
          @click="saveHandle"
          :loading="saveLoading"
      >保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { queryMerchantList } from "@/api/ib/ibMerchantInfo";
import accountRole from '@/components/common/accountRole'
import { saveTransUserInfo } from "@/api/ib/tCustMtaccount";
import checkPermission from "@/utils/permission";
import { mapGetters } from "vuex";
import { initData } from '@/api/data'
import { openMT4Acc } from '@/api/cus/tCustInfo'
export default {
  name: 'openMt4Dialog',
  components: {
    accountRole
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: false,
    },
    currency:{
      type: String,
    },
    // 美分账户,下拉只显示美分分组,
    // 其他账户, 下拉只显示美元分组
    groupSelf:{
      type: Object,
      default(){
        return {};
      }
    },
  },
  data() {
    return {
      leverList: [],
      customerList: [],
      groupList: [],
      loading: false,
      form: {
        custId: '',
        mtGroup: '',
        leverage: '',
        nickname: '',
      },
      rules: {
        custId: [
          { required: true,message: '请选择',trigger: 'blur' }
        ],
        mtGroup: [
          { required: true,message: '请选择',trigger: 'change' }
        ],
        leverage: [
          { required: true,message: '请选择',trigger: 'change' },
        ],
        nickname: [
          { required: true,message: '请输入昵称',trigger: 'blur' },
        ],
      },
      saveLoading: false,
    }
  },
  computed: {
    ...mapGetters(["mtGroups"]),
    groupFilter(){
      if(this.currency == 'USD'){
        let filterObject = {};
        for (let key in this.groupSelf) {
          if(key !== 'Cent'){
            filterObject[key] = this.groupSelf[key];
          }
        }
        return filterObject;
      }else {
        let filterObject = {};
        for (let key in this.groupSelf) {
          if(key == 'Cent'){
            filterObject[key] = this.groupSelf[key];
          }
        }
        return filterObject;
      }
    },
  },
  watch:{
    "form.mtGroup": {
      handler(newVal, oldVal) {
        if(newVal){
          this.matchLeverageList();
        }
      },
      deep: true
    },
    "form.custId": {
      handler(newVal, oldVal) {
        if(newVal){
          this.getGroupList()
        }else {
          this.groupList = [];
        }
      },
      deep: true
    }
  },
  methods: {
    checkPermission,
    getGroupList(){
      const url='/crm/tMt4Group'
      let parmas = {
        isMock:2,
        custId: this.form.custId
      }
      initData(url, parmas).then(res => {
        this.groupList = res.content;
      }).catch(err => {

      })
    },
    getAccountList(query){
      if (query !== '') {
        this.getCustMtaccount(query,() => {
        });
      } else {
        this.customerList = [];
      }
    },
    getCustMtaccount(mt4Info,callback) {
      let parmas = {
        size: 40,
        page: 0,
      };
      if(mt4Info){
        parmas.basicInfo = mt4Info;
      }
      this.loading = true;
      queryMerchantList(parmas).then((res) => {
        console.log('账户列表',res.content);
        this.customerList = res.content;

        callback && callback();
        this.loading = false;
      });
    },
    closeDialog(){
      this.$refs.form.clearValidate();
      this.$refs.form.resetFields();
      this.$emit('close-dialog');
    },
    beforeHandle(){
      this.closeDialog();
    },
    matchLeverageList(){
      for (let i = 0; i < this.groupList.length; i++) {
        if(this.groupList[i].groupName == this.form.mtGroup){
          this.setLeverage(this.groupList[i].leverageList);
          break;
        }
      }
    },
    saveHandle(){
      this.$refs.form.validate((valid) => {
        if(valid){
          const params = {
            custId: this.form.custId,
            group: this.form.mtGroup,
            leverage: this.form.leverage,
            alias: this.form.nickname,
          };
          //接口调用
          this.saveLoading = true;
          openMT4Acc(params)
              .then(res => {
                this.saveLoading = false;
                this.$notify({
                  title: '成功',
                  type: "success",
                  duration: 2500
                });
                this.reset();
                this.closeDialog();
                this.
                this.$emit('refresh');
              })
              .catch(err => {
                this.saveLoading = false;
              });
        }else {
          console.log('no-pass');
        }
      })
    },
    reset(){
      this.leverList = []
      this.customerList = []
      this.groupList = []
      this.form = {
        custId: '',
        mtGroup: '',
        leverage: '',
        nickname: ''
      }
    },
    setLeverage(leverageList) {
      let levList = leverageList.split(",");
      let tempList = [];
      for (let i = 0; i < levList.length; i++) {
        let lev = parseInt(levList[i]);
        let levName = "1:" + levList[i];
        let params = {
          levName: levName,
          lev: lev
        };
        tempList[i] = params;
      }
      this.leverList = tempList;
    },
  }
}
</script>
<style lang="less" scoped>
.openMt4account-dialog {
  .select-account {
    width: 100%;
  }
}
.content {
  padding: 0 15px;
}
</style>
