<template>
  <el-dialog
    append-to-body
    :visible.sync="dialogShow"
    title="编辑账户标签"
    width="500px"
    class="edittag-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-width="80px"
    >
      <el-form-item
          label="账户标签"
      >
        <el-select
            class="filter-item"
            v-model="form.tag"
            placeholder="账户标签"
            clearable
        >
          <el-option
              v-for="(item,index) in tagOption"
              :key="index"
              :label="item.tagName"
              :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          label="备注"
          prop="comment"
      >
        <el-input
          type="textarea"
          v-model="form.comment"
          style="width: 370px;"
          placeholder="不得超过200个字符"
          show-word-limit
          maxlength="200"
          rows="5"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button
          v-if="checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_TRADE_INFO'])"
          :loading="loading"
          type="primary"
          @click="doSubmit"
      >确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getAccountTagList,editAccountTag } from "@/api/ib/tCustMtaccount";
  import checkPermission from '@/utils/permission'
export default {
  name: 'edittagDialog',
  props: {
    rowData: {
      type: Object,
      default(){
        return {
          id: '',
          tagId: '',
          remark: '',
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      dialogShow: false,
      form: {
        id: '',
        tag: '', //
        comment: '',
      },
      rules:{
        tag:[
          {
            required: true,message: '请选择标签', trigger: 'change'
          },
        ],
        comment:[
          /*{
            required: true,message: '请输入备注', trigger: 'blur'
          },*/
        ],
      },
      tagOption: [],
    }
  },
  watch:{
    dialogShow(newValue,oldValue){
      if(!newValue){
        this.resetForm();
      }
    },
    rowData:{
      handler(newValue){
        if(newValue){
          this.form.id = newValue.mtId;
          this.form.tag = newValue.tagId;
          this.form.comment = newValue.remark;
        }
      },
      deep: true,
    }
  },
  methods: {
    checkPermission,
    getTagInfo(){
      getAccountTagList().then((res) => {
        this.tagOption = res;
      })
    },
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid){
          this.loading = true;
          let params = {
            id: this.rowData.mtId,
            tagId: this.form.tag,
          };
          if(this.form.comment){
            params.remark = this.form.comment;
          }
          editAccountTag(params).then(res => {
            this.resetForm()
            this.$notify({
              title: '编辑成功',
              type: 'success',
              duration: 2500
            })
            this.loading = false;
            this.$parent.init();
          }).catch(err => {
            this.loading = false;
            console.log(err.response.data.message);
          })
        }else {
          console.log('no pass');
        }
      })
    },
    resetForm() {
      this.dialogShow = false;
      this.$refs['form'].resetFields();
      this.form = {
        id: '',
        tag: '',
        comment: '',
      }
    },

  }
}
</script>

<style lang="less" scoped>
.edittag-dialog {
  p {
    margin: 0;
    padding: 0;
  }
}
</style>
