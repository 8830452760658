<template>
  <el-dialog
    :append-to-body="true"
    v-if="dialog"
    :visible.sync="dialog"
    title="上账"
    width="540px"
    class="accountmgr-golden-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-width="80px"
    >
      <el-form-item label="客户姓名">
        <p>{{rowData.userName}}</p>
      </el-form-item>
      <el-form-item label="MT4账号">
        <p>{{rowData.login}}</p>
      </el-form-item>
      <el-form-item label="上账金额" prop="goldenCount">
        <div class="count-box pr">
          <span class="pa sufxx">$</span>
          <el-input
            v-model="form.goldenCount"
            style="width: 370px;"
            placeholder="请输入"
          />
        </div>
      </el-form-item>
      <el-form-item label="上账方式" prop="goldenType">
        <el-radio v-model="form.goldenType" :label="1">COMMI_BONUS</el-radio>
        <el-radio v-model="form.goldenType" :label="2">其他</el-radio>
      </el-form-item>
      <el-form-item label="备注" prop="comment">
        <el-input
          type="textarea"
          v-model="form.comment"
          style="width: 370px;"
          placeholder="不得超过200个字符"
          show-word-limit
          maxlength="200"
          rows="5"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button
          :loading="loading"
          type="primary"
          @click="doSubmit"
      >确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { twoDecimal } from '@/utils/validate'
import { goldenToMt4 } from '@/api/ib/ibMerchantData'
export default {
  props: {
    rowData: {
      type: Object,
      default(){
        return {
          userName: '',
          login: '',
        }
      }
    }
  },
  data() {
    var validatorGoldenCount = (rule, value, callback) => {
      setTimeout(() => {
        if (!twoDecimal(value)) {
          callback(new Error('请输入数字值,最多两位小数'));
        } else {
          callback();
        }
      }, 300);
    };
    return {
      loading: false,
      dialog: false,
      form: {
        id: '',
        goldenCount: '',
        goldenType: 1,
        comment: '',
      },
      rules:{
        goldenCount:[
          {
            required: true,message: '请输入金额', trigger: 'blur'
          },
          {
            validator: validatorGoldenCount, trigger: 'blur'
          }
        ],
      }
    }
  },
  watch:{
    dialog(newValue,oldValue){
      if(!newValue){
        this.resetForm();
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid){


          let params = {
            custId: this.rowData.id,
            login: this.rowData.login,
            amount: this.form.goldenCount,
            payType: this.form.goldenType == 1 ? 35 : 99,
          };
          if(this.form.comment){
            params.remark = this.form.comment;
          }
          if(this.loading) return;
          this.loading = true;

          goldenToMt4(params).then(res => {
            this.loading = false;
            this.$parent.init();
            this.resetForm()
            this.$notify({
              title: '入金成功',
              type: 'success',
              duration: 2500
            })

          }).catch(err => {
            this.loading = false;
            console.log(err.response.data.message);
          })
        }else {
          console.log('no pass');
        }
      })
    },
    resetForm() {
      this.dialog = false;
      this.$refs['form'].resetFields();
      this.form = {
        id: '',
        goldenCount: '',
        goldenType: 1,
        comment: '',
      }
    },

  }
}
</script>

<style lang="less">
.accountmgr-golden-dialog {
  p {
    margin: 0;
    padding: 0;
  }
  .count-box {
    .sufxx {
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      z-index: 99;
    }
    .el-input__inner {
      padding-left: 30px;
    }
  }
}
</style>
