import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'crm/tMerchantData',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'crm/tMerchantData/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'crm/tMerchantData',
    method: 'put',
    data
  })
}


// 用户管理列表页面入金接口
export function goldenToMt4(data) {
  return request({
    url: 'crm/depositByAdmin',
    method: 'post',
    data
  })
}
