import request from '@/utils/request'


export function resetCustPassword(params) {
    return request({
        url: 'crm/tCustMtaccount/resetPwd',
        method: 'get',
        params
    })
}


export function saveTransUserInfo(data) {
    return request({
        url: 'crm/updateMt4TradeUser',
        method: 'post',
        data
    })
}

export function getAccountTagList(data) {
    return request({
        url: 'crm/validAccountTag',
        method: 'get',
        params:data
    })
}

export function editAccountTag(data) {
    return request({
        url: 'crm/tCustMtaccount/modifyTag',
        method: 'put',
        data
    })
}

export function toggleActiveStatus(data) {
    return request({
        url: 'crm/tCustMtaccount/toggleActiveStatus',
        method: 'put',
        data
    })
}
