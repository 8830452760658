<template>
    <div class="tag-box account-role"
        :class="{hascolor: getColor()}"
    >
        <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content"
                :style="styleComputd"
            >
                {{tagTipsText}}
            </div>
            <div class="tag fs12" v-if="tipsFlag">{{getTagText}}</div>
        </el-tooltip>
        <div class="tag fs12" v-if="!tipsFlag">{{getTagText}}</div>
    </div>
</template>

<script>
  export default {
    name: 'accountRole',
    props: {
      accountInfo: {
        type: Object,
        default(){
          return {
            businessType: '',
            tier: '',
          }
        }
      }
    },
    computed: {
      getTagText() {
        switch (this.accountInfo.businessType) {
          case 0:
            return '普通';
          case 1:
            return this.accountInfo.tier == 1 ? 'Social主': 'Social投资';
          case 2:
            return this.accountInfo.tier == 1 ? 'MAM主': 'MAM投资';
          case 3:
            return '钱包';
          case 5:
            return '模拟';
          case 6:
            return '体验';
        }
      },
      tagTipsText() {
        switch (this.accountInfo.businessType) {
          case 0:
            return '支持自主交易';
          case 1:
            return this.accountInfo.tier == 1 ? 'Social跟单信号源账户，支持自主交易': '用于复制Social信号源账户进行投资，支持自主交易';
          case 2:
            return this.accountInfo.tier == 1 ? '可接受MAM投资账户委托管理其账户资金并收取相应费用，支持自主交易；': '可委托MAM主账户管理账户资金，不支持自主交易';
          case 3:
            return '用于订阅收入和管理费支出，支持自主交易';
        }
      },
      tipsFlag() {
        if(this.accountInfo.businessType == 5 || this.accountInfo.businessType == 6) {
          return false;
        }else {
          return true;
        }
      },
      styleComputd(){
        if(this.accountInfo.businessType === 0){
          return {
            width: '72px'
          }
        }else {
          return {
            width: '240px'
          }
        }
      }
    },
    methods: {
      getColor(){
        if(this.accountInfo.businessType == 1 || this.accountInfo.businessType == 2) {
          return true;
        }else {
          return false;
        }
      }
    }
  };
</script>

<style lang="less" scoped>
.account-role {
    display: inline-block;
    margin-top: 10px;
    .tag {
        display: inline-block;
    }
    &.hascolor {
        .tag {
            background-color: rgba(240,138,93, .1);
            color: #F08A5D;
        }
    }
}
</style>
